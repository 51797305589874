<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card no-body class="border mt-1">
        <b-card-body>
          <b-col xl="4">
            <b-form-group label-for="name">
              <validation-provider #default="{ errors }" name="name" rules="required">
                <label>Server </label>

                <b-form-input
                  id="helperInput"
                  placeholder=""
                  v-model="Serverip"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" >
        <b-button variant="purple" @click="toggleServer">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">إضافة </span>
        </b-button>
      </b-col>
        </b-card-body>
       

        <br /><br />
        <!-- <b-col xl="4"></b-col> -->
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BTable,BFormInput,BFormGroup,BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import Vue from 'vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
  BCard, BTable,BFormInput,BFormGroup,BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
    BCard, BTable,BFormInput ,BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BCol,ValidationProvider, ValidationObserver
     ,required, ToastificationContent
  },

  data(){
    return{
 Serverip:''
    }
  },

  created(){
 
  },

  // setup() {
  //   const

  // },
  methods:{
    toggleServer(){
  
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules
          .validate()
          .then((success) => {
            if (success) {

        //console.log(Vue.prototype.$http.defaults.baseURL)
      // //console.log(axiosIns.baseURL)
     Vue.prototype.$http.defaults.baseURL=this.Serverip
          //console.log(Vue.prototype.$http.defaults.baseURL)


            }})})
  
  }}
};
</script>

<style scoped>
table tbody tr td {
  color: #00468e !important;
  font-weight: bold !important;
  font-family: "Cairo", sans-serif !important;
}
.blue {
  background-color: #00468e !important;
}
label {
  color: white !important;;
  font-weight: bold;
  font-size: 15px;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  margin-top: 100px;
  margin-left: 10px;
  background-color: #00468e !important;
  width: 200px;
}
</style>